import axios, { InternalAxiosRequestConfig } from "axios";
import { loadingPublisher } from "./loading";
import { getToken } from "./token";
import { getLang } from "./user";


export const request = axios.create({
  // baseURL: "http://dev.api.intrinsic.edu.hk/cms",
  baseURL: 'https://dev.api.intrinsic.edu.hk/cms',
  // baseURL: "http://dev.api.cheffymade.com/cms",
  // baseURL: 'http://api.the-kids.org/cms',

  timeout: 600000,
});

request.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const token = getToken();
    if (token) {
      config.headers["X-Token"] = token;
    }
    const lang = getLang();
    config.headers["lang"] = lang || 'cn';
    loadingPublisher.add()
    return config;
  },
  (error) => {
    loadingPublisher.sub()
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  (response) => {
    loadingPublisher.sub()
    return response;
  },
  (error) => {
    loadingPublisher.sub()
    if (
      (error?.response.status === 500 &&
        error?.response?.data?.message === "401") ||
      error?.response.status === 401
    ) {
      // 返回登录
      // let nativate = useNavigate();
      // nativate('/login')
      // window.location.hash="/login"
      window.location.pathname = "/login";
    }

    return Promise.reject(error);
  }
);
